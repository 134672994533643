body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-container {
  max-width: 435px; /* Maximum width */
  background-color: #333;
  margin: 0 auto; /* Center horizontally */
  height: auto; /* Adjust based on content, or set a fixed height */
  box-shadow: 0 0 10px rgba(0,0,0,0.5); /* Optional: adds shadow for better visualization */
  overflow: hidden; /* Optional: ensures nothing spills outside the container */
}

