.gallery-grid {
    display: grid;
    grid-gap: 20px;
    padding: 10px; /* Add some padding around the grid */
  }
  
  .columns-1 {
    grid-template-columns: repeat(1, 1fr);
  }
  
  .columns-2 {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .columns-3 {
    grid-template-columns: repeat(3, 1fr);
  }
  
  .columns-4 {
    grid-template-columns: repeat(4, 1fr);
  }
  
.photo-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 16px;
    filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
  }
  
  .photo-card-header {
    position: relative;
    padding-bottom: 3px;
    font-weight: bold;
  }
  
  .player-entry-number {
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    font-size: 1.5rem;
    background-color: #ff09ab;
    border-radius: 50%;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
  }
  
  .photo-card-image {
    width: 100%;
    border-radius: 5px;
  }
  
  .prompt-text {
    font-weight: bold;
    font-size: 0.75rem;
  }
  
  .download-buttons {
    display: flex;
    margin-top: 0.15rem;
  }